import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/info";
export const PageQuery_info_nl_businessmdx = graphql`
        query PageQuery_info_nl_businessmdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "nl"}, ns: {in: ["common", "info"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/business/"},
                    language: {eq: "nl"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Onderneming",
  "path": "/info/business",
  "date": "2022-09-29T00:00:00.000Z"
};
const layoutProps = {
  PageQuery_info_nl_businessmdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "-info",
      "style": {
        "position": "relative"
      }
    }}>{`🧀 Info`}<a parentName="h1" {...{
        "href": "#-info",
        "aria-label": " info permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <blockquote>
      <p parentName="blockquote">{`Mijn specialisaties liggen in streaming, (code) architectuur, high-performance werk en het refactoren van complexe codebases.`}</p>
    </blockquote>
    <h2 {...{
      "id": "-hallo",
      "style": {
        "position": "relative"
      }
    }}>{`👋🏻 Hallo!`}<a parentName="h2" {...{
        "href": "#-hallo",
        "aria-label": " hallo permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Ik ben Richard. In het afgelopen decennium heb ik de kans gekregen om bij te dragen aan de vooruitgang van streamingtechnologie in de `}<a parentName="p" {...{
        "href": "/service/backend"
      }}>{`film-`}</a>{` en `}<a parentName="p" {...{
        "href": "/service/techlead"
      }}>{`sportwereld`}</a>{`. Wellicht heeft U het resultaat van dit werk al eens voorbij zien komen op het grote of kleine scherm.`}</p>
    <h2 {...{
      "id": "-freelance-werk",
      "style": {
        "position": "relative"
      }
    }}>{`👏🏻 Freelance werk`}<a parentName="h2" {...{
        "href": "#-freelance-werk",
        "aria-label": " freelance werk permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <p>{`Mocht het werk op de dienstenpagina's U bevallen, dan kunt U mij inhuren om uw team te versterken.`}</p>
    <p>{`Wanneer ik geen freelance opdrachten doe besteed ik mijn tijd aan (toekomstige) producten en willekeurige `}<a parentName="p" {...{
        "href": "https://github.com/cheesebyte"
      }}>{`open source projecten`}</a>{`. Zie het menu hierboven voor meer daarover en mijn werkervaring.`}</p>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      